import {Layout, Flex, Col, Row, Card, Button, Input, Space} from "antd"
import {useState, useEffect, useCallback, useRef} from "react"
import {CheckSquareOutlined} from "@ant-design/icons"
const { TextArea } = Input;


const Results = ({results}) => {
    const [sent, setSent] = useState(false)

    return (
        <Layout style={{height: "100vh", backgroundColor: "#08979c"}}>
            <Flex justify="center" vertical={false} style={{height: "100%"}}>
                <Flex justify="center" vertical={true} style={{height: "100%"}}>
                    <Card style={{width: 1100, height: 600}}>
                        <div style={{position: "absolute", float:"left"}}>
                        <img src="/logo.jpg" style={{height: 70}}/>
                        </div>
                        <Row>
                            <Col span={12} style={{marginTop: 50}}>
                                <Flex vertical align="flex" justify="space-between" style={{padding: 42}}>
                                    <h1>Vacature: Marketing Manager</h1>
                                    <h2>It's done. Bedankt!</h2>
                                    <p>Dit is voorlopig alle info die we nodig hebben.
                                        Je ontvangt binnenkort een mailtje om je te laten weten of we een volgend
                                        gesprek met je inplannen.</p>
                                </Flex>
                            </Col>
                            <Col span={12}>
                                {!sent?
                                <div style={{backgroundColor: "#08979c", padding: 20, margin: 20, borderRadius: 10, color: "white", marginTop: 160}}>
                                <Space direction="vertical" style={{width: "100%"}}>
                                    <h2>Nog iets vergeten?</h2>
                                    <TextArea
                                        //value={value}
                                        //onChange={(e) => setValue(e.target.value)}
                                        placeholder="Ben je iets vergeten te zeggen of wens je nog iets toe te voegen?"
                                        autoSize={{
                                            minRows: 3,
                                            maxRows: 5,
                                        }}
                                    />
                                    <Button onClick={()=>setSent(true)}>Verzenden</Button>
                                </Space>
                                </div>:
                                    <div style={{marginLeft: "20%"}}>
                                    <div style={{backgroundColor: "#08979c", padding: 20, margin: 20, borderRadius: 10, color: "white", marginTop: 160, fontSize: 100, width: 200, textAlign: "center"}}>
                                        <CheckSquareOutlined />
                                    </div>
                                    </div>}
                            </Col>
                        </Row>

                    </Card>
                </Flex></Flex>

        </Layout>
    );


}
export default Results