import React from 'react';
import { createRoot } from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './App';
import "./index.css"

const root = createRoot(document.getElementById('root'));

root.render(
    <Auth0Provider
        domain="ai-recruiter.eu.auth0.com"
        clientId="JrhujROnSMHqeR3mLrG69Irgz4zVuBaY"
        redirectUri={window.location.origin}
    >
        <App />
    </Auth0Provider>,
);