import {Col, Row, Card, Space, Typography, Tag, Collapse, Button, Modal, Form, Input, Badge} from 'antd';
import {useContext, useEffect, useState} from "react";
import BackendService from "../services/BackendService";
import {useParams} from "react-router-dom";
import {VacancyContex} from "../providers/VacancyProvider";
import {PlusCircleOutlined, EditOutlined, DeleteOutlined} from "@ant-design/icons"

const {Paragraph} = Typography;

const KnockoutModule = (props) => {
    const [modal, setModal] = useState(false)
    const [index, setIndex] = useState(null)
    const [item, setItem] = useState(null)
    const [form] = Form.useForm()

    const onFinish = (values) => {
        if (item) {
            props.change(index, {tag: values.name, description: values.description})
        } else {
            props.add({tag: values.name, description: values.description})
        }
        setModal(false);
    };

    function edit(index) {
        setIndex(index)
        setItem(props.vacancy.knockout[index])
        form.resetFields()
        setModal(true)
    }

    function delete_field(index) {
        props.delete(index)
    }

    function create() {
        setIndex(null)
        setItem(null)
        form.resetFields()
        setModal(true)
    }


    const infoItems = props.vacancy?.knockout?.map((item, index) =>
        ({
            key: item.tag,
            label: <div>{item.tag} <Space className="module-buttons"><EditOutlined onClick={() => edit(index)}/>
                <DeleteOutlined onClick={() => delete_field(index)}/></Space></div>,
            children:
                <p>{item.description}</p>,
        })
    )

    return <><Modal open={modal} footer={[]} onCancel={() => setModal(false)}>
        Create new knockout question.
        <Form
            form={form}
            name="basic"
            labelCol={{
                span: 8,
            }}
            wrapperCol={{
                span: 16,
            }}
            style={{
                maxWidth: 600,
            }}
            initialValues={{
                name: item?.tag,
                description: item?.description,
            }}
            onFinish={onFinish}
            //onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Form.Item
                label="Name"
                name="name"
                rules={[{required: true}]}
            ><Input/></Form.Item>

            <Form.Item
                label="Description"
                name="description"
                rules={[{required: true}]}
            ><Input.TextArea/></Form.Item>

            <Form.Item
                wrapperCol={{
                    offset: 8,
                    span: 16,
                }}>
                <Button type="primary" htmlType="submit">{index ? "Create" : "Save"}</Button>
            </Form.Item>
        </Form>
    </Modal>
        <Card title={<Space>Knockout Questions<Tag color="green">Ingeschakeld</Tag></Space>} size="large">
            <div className="module-buttons"><PlusCircleOutlined onClick={create}/></div>
            <Collapse accordion items={infoItems}/>
        </Card>
    </>
}


const InfoModule = (props) => {
    const [modal, setModal] = useState(false)
    const [index, setIndex] = useState(null)
    const [item, setItem] = useState(null)
    const [form] = Form.useForm()

    const onFinish = (values) => {
        console.log(index)
        if (index) {
            //props.change(index, {tag: values.name, description: values.description})
        } else {
            props.add({tag: values.name, description: values.description})
        }
        setModal(false);
    };

    function edit(index) {
        setIndex(index)
        setItem(props.vacancy.info[index])
        form.resetFields()
        setModal(true)
    }

    function delete_field(index) {
        props.delete(index)
    }

    function create() {
        setIndex(null)
        setItem(null)
        form.resetFields()
        setModal(true)
    }


    const infoItems = props.vacancy?.info?.map((item, index) =>
        ({
            key: item.tag,
            label: <div>{item.tag} <Space className="module-buttons"><EditOutlined onClick={() => edit(index)}/>
                <DeleteOutlined onClick={() => delete_field(index)}/></Space></div>,
            children:
                <p>{item.description}</p>,
        })
    )

    return <><Modal open={modal} footer={[]} onCancel={() => setModal(false)}>
        Create new question.
        <Form
            form={form}
            name="basic"
            labelCol={{
                span: 8,
            }}
            wrapperCol={{
                span: 16,
            }}
            style={{
                maxWidth: 600,
            }}
            initialValues={{
                name: item?.tag,
                description: item?.description,
            }}
            onFinish={onFinish}
            //onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Form.Item
                label="Name"
                name="name"
                rules={[{required: true}]}
            ><Input/></Form.Item>

            <Form.Item
                label="Description"
                name="description"
                rules={[{required: true}]}
            ><Input.TextArea/></Form.Item>

            <Form.Item
                wrapperCol={{
                    offset: 8,
                    span: 16,
                }}>
                <Button type="primary" htmlType="submit">{index ? "Create" : "Save"}</Button>
            </Form.Item>
        </Form>
    </Modal>
        <Card title={<Space>Necessary Information<Tag color="green">Ingeschakeld</Tag></Space>} size="large">
            <div className="module-buttons"><PlusCircleOutlined onClick={create}/></div>
            <Collapse accordion items={infoItems}/>
        </Card>
    </>
}


const VacancyOverview = () => {
    const [editableStr, setEditableStr] = useState("Als Senior Marketing Manager stippel je de marketingstrategie voor de B2C- en B2B-markt uit. Je speelt in op de noden en wensen van onze klanten en maakt het aanbod van NMBS zo aantrekkelijk mogelijk. En zo speel je een cruciale rol in één van onze hoofddoelstellingen: zorgen voor meer & tevreden reizigers.")
    const [vacancy, setVacancy] = useState(null);
    const [loading, setLoading] = useState(true);
    const {vacancies, update} = useContext(VacancyContex);
    const {id} = useParams()



    useEffect(() => {
        const backend = new BackendService();
        async function fetchData() {
            const vac = await backend.getVacancy(id);
            setVacancy(vac);
            setLoading(false)
        }

        fetchData();

    }, [vacancies]);

    function addInfo(item) {
        let info = vacancy.info
        info.push(item)
        const changed = {
            ...vacancy,
            info: info
        }
        setVacancy(changed)
        update(changed)

    }

    function deleteInfo(index) {
        const info = vacancy.info
        const changed = {
            ...vacancy,
            info: [...info.slice(0, index), ...info.slice(index + 1)]
        }
        setVacancy(changed)
        update(changed)

    }

    function changeInfo(index, item) {
        let info = vacancy.info
        info[index] = item
        const changed = {
            ...vacancy,
            info: info
        }
        setVacancy(changed)
        update(changed)
    }

    function addKnockout(item) {
        let knockout = vacancy.knockout
        knockout.push(item)
        const changed = {
            ...vacancy,
            knockout: knockout
        }
        setVacancy(changed)
        update(changed)

    }

    function deleteKnockout(index) {
        const knockout = vacancy.knockout
        const changed = {
            ...vacancy,
            knockout: [...knockout.slice(0, index), ...knockout.slice(index + 1)]
        }
        setVacancy(changed)
        update(changed)

    }

    function changeKnockout(index, item) {
        let knockout = vacancy.knockout
        knockout[index] = item
        const changed = {
            ...vacancy,
            knockout: knockout
        }
        setVacancy(changed)
        update(changed)
    }


    if (loading)
        return <></>

    return <>
        <Button type="primary" style={{position: "absolute", right: 30}} href="/interview/123"> Test Gesprek</Button>
        <h2>Vacature: {vacancy.name}</h2>
        <Row gutter={[30, 30]}>
            <Col span={24}>
                <Card title={<>Basis Informatie</>} size="large"
                      bordered={false}>
                    <Paragraph editable={{onChange: setEditableStr}}>{editableStr}</Paragraph>
                    <div style={{position: "absolute", bottom: 15, right: 15}}>
                    </div>
                </Card>
            </Col>
            <Col span={12}>
                <InfoModule vacancy={vacancy} add={addInfo} change={changeInfo} delete={deleteInfo}/>
            </Col>
            <Col span={12}>
                <KnockoutModule vacancy={vacancy} add={addKnockout} change={changeKnockout} delete={deleteKnockout}/>
            </Col>
            <Col span={12}>
                <Card title={<Space>Taal Test<Tag color="red">Uitgeschakeld</Tag></Space>} size="large">
                </Card>
            </Col>

        </Row>
        <div style={{marginTop: 50}}>
            <Card title={<Space>Kandidaten</Space>} size="large">
                {vacancy && vacancy?.interviews?.map(i => (
                    <Card>Ward Van Laer {i.result ? <Tag color="green">Uitgevoerd</Tag> :
                        <Tag color="orange">Uitnodiging verstuurd</Tag>}{i.result &&
                        <div><h3>Resultaten taaltest Engels <Badge count={i.result.mean} color="#faad14"/></h3>
                            <p>Vlotheid: <Badge count={i.result.fluency + " %"} color="#faad14"/></p>
                            <p>Grammatica: <Badge count={i.result.grammar + " %"} color="#faad14"/></p>
                            <p>Woordenschat: <Badge count={i.result.vocabulary + " %"} color="#faad14"/></p>
                            <p>{i.result.feedback}</p></div>}</Card>))}
            </Card>

        </div>


    </>


}
export default VacancyOverview