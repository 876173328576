import {useParams} from "react-router-dom";
import {Result, Layout, Flex, Col, Row, Card, Button} from "antd"
import {useState, useEffect, useCallback, useRef} from "react"
import Webcam from "react-webcam";
import Interview from "../Interview";
import {LiveAudioVisualizer} from "react-audio-visualize";


const Conversation = ({history, recorder}) => {
    const dummy = useRef(null);


    useEffect(() => {
        if(dummy.current)
        dummy.current.scrollIntoView({ behavior: "smooth" , speed: 100});
    }, [history]);


    return (
        <Flex vertical align="flex" justify="space-between" style={{padding: 42}}>
            <h1>Vacature: Marketing Manager</h1>
            <div style={{overflow: "hidden", height: 250}}>
            {history.map((item, index) =>
                <div
                    ref={history.length - index ==1 ? null: dummy}
                style={{backgroundColor: "#08979c", color: "white", padding: 15, borderRadius: "0px 15px 15px 15px", margin: 7}}>
                {item}</div>)}


                <div style={{height: 250}}></div>

            </div>

        </Flex>
    );


}
export default Conversation