import {Card, Button, Tag, Row, Col, Space} from 'antd';
import {Stat} from "./Statistic";
import {CheckSquareOutlined, ClockCircleOutlined, PieChartOutlined} from "@ant-design/icons"
import {useNavigate} from "react-router-dom";


const Vacancy = (props) => {
    const navigate = useNavigate()

    const done = props.data.interviews.filter(i => i.state == "DONE");


    function getTag(data) {
        if (data.state == "RUNNING")
            return <Tag style={{marginLeft: 10}} color="#089c57">RUNNING</Tag>

        return <Tag style={{marginLeft: 10}} color="#0D089C">NIEUW</Tag>


    }


    return <Card title={<>{props.data.name}{getTag(props.data)}</>} size="small"
                 bordered={false}>
        <div style={{position: "absolute", top: 15, right: 15}}>
            <Space direction="horizontal" size="middle" style={{display: 'flex'}}>
                <Button type={"primary"}>Voeg sollicitanten toe</Button>
                {props.data.state !== "RUNNING" &&
                    <Button onClick={() => navigate("vacancy/" + props.data._id)}>Configureer</Button>}
            </Space>
        </div>
        <div style={{position: "absolute", bottom: 15, right: 15}}>
            <Space direction="horizontal" size="middle" style={{display: 'flex'}}>
                <Button type={"primary"} onClick={() => navigate("vacancy/" + props.data._id)}>Resultaten</Button>
            </Space>
        </div>
        <Row gutter={16}>
            <Col className="gutter-row" span={12}>
                <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                    <Stat icon={<ClockCircleOutlined/>} number={props.data.interviews.length}
                          description="Geplande interviews"/>
                    <Stat icon={<CheckSquareOutlined/>} number={done.length} description="Uitgevoerde interviews"/>
                    <Stat icon={<PieChartOutlined/>} number={8} description="Geselecteerde kandidaten"/>
                </Space>
            </Col>
        </Row>
    </Card>
}
export default Vacancy