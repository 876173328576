import {useParams} from "react-router-dom";
import {Result, Layout, Flex, Col, Row, Card, Button, Select, Space} from "antd"
import {useState, useEffect, useCallback, useRef} from "react"
import Webcam from "react-webcam";
import Conversation from "./interview/Conversation";
import Results from "./interview/Results";
import {RetellWebClient} from "retell-client-js-sdk";
import {LiveAudioVisualizer} from 'react-audio-visualize';
import {useAudioRecorder} from 'react-audio-voice-recorder';
import BackendService from "../services/BackendService";


const agentId = process.env.REACT_APP_AGENT_ID;
const domain = process.env.REACT_APP_URL
const wss_domain = process.env.REACT_APP_URL_WS


const webClient = new RetellWebClient();
const initialize = (setIsCalling, setEnd) => {

    // Setup event listeners
    webClient.on("call_started", () => {
        console.log("conversationStarted");
    });

    webClient.on("audio", (audio) => {
    });

    webClient.on("call_ended", () => {
        setEnd(true);
        setIsCalling(false); // Update button to "Start" when conversation ends
        //setResults(-1);
    });

    webClient.on("error", (error) => {
        console.error("An error occurred:", error);
        setIsCalling(false); // Update button to "Start" in case of error
    });

    webClient.on("update", (update) => {
        // Print live transcript as needed
        console.log("update", update);
    });

}

async function registerCall(agentId, interviewId) {
    try {
        console.log({
            agentId: agentId,
            interviewId: interviewId
        });
        // Replace with your server url
        const response = await fetch(
            `${domain}/register-call-on-your-server`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    agentId: agentId,
                    interviewId: interviewId
                }),
            },
        );

        if (!response.ok) {
            throw new Error(`Error: ${response.status}`);
        }

        return await response.json();
    } catch (err) {
        console.log(err);
        throw new Error(err);
    }
}

const DoInterview = (props) => {
    const id = props.interview?._id.$oid;
    const [loading, setLoading] = useState(false);
    const [isCalling, setIsCalling] = useState(false);

    const [history, setHistory] = useState([]);
    const [end, setEnd] = useState(false);
    const [results, setResults] = useState(null);
    const websocketRef = useRef(null);
    const recorder = useAudioRecorder();
    const [deviceId, setDeviceId] = useState(null);
    const [devices, setDevices] = useState([]);
    const [audioDeviceId, setAudioDeviceId] = useState(null);
    const [audioDevices, setAudioDevices] = useState([]);
    const [permissions, setPermissions] = useState(false);


    const handleDevices = useCallback(
        mediaDevices => {
            const list = mediaDevices.filter(({kind}) => kind === "videoinput");
            setDevices(list);
            if (list.length > 0)
                setDeviceId(list[0].deviceId)
            const list2 = mediaDevices.filter(({kind}) => kind === "audioinput");
            setAudioDevices(list2);
            if (list2.length > 0)
                setAudioDeviceId(list2[0].deviceId)
        },
        [setDevices]
    );


    useEffect(() => {
        navigator.mediaDevices.getUserMedia({audio: true});
        navigator.mediaDevices.enumerateDevices().then(handleDevices);
        if (!permissions) {
            const intervalId = setInterval(async () => {  //assign interval to a variable to clear it.
                const PermissionStatus = await navigator.permissions.query({
                    name: "camera"
                });
                console.log(PermissionStatus)
                if (PermissionStatus.state === "granted")
                    setPermissions(true)
            }, 500)

            return () => clearInterval(intervalId);
        }//This is important

    }, [permissions, handleDevices])

    useEffect(() => {
        //setLoading(true);
        //getResults();
    }, [isCalling]);

    useEffect(() => {
        recorder.startRecording();
        initialize(setIsCalling, setEnd);
    }, [recorder]);

    useEffect(() => {
        return () => {
            // Clean up function
            if (websocketRef.current) {
                websocketRef.current.close();
            }
        };
    }, []);

    const toggleConversation = async () => {
        if (isCalling) {
            console.log(end)
            setLoading(true);
            webClient.stopConversation();
        } else {
            const registerCallResponse = await registerCall(agentId, id);
            console.log(registerCallResponse)
            if (registerCallResponse.callId) {
                webClient
                    .startCall({
                        accessToken: registerCallResponse.accessToken,
                    })
                    .catch(console.error);
                const url = `${wss_domain}/update/` + registerCallResponse.callId; // WebSocket URL
                const websocket = new WebSocket(url);
                websocketRef.current = websocket;
                // When WebSocket connection is open
                websocket.onmessage = (event) => {
                    console.log(event.data);
                    const response = JSON.parse(event.data);
                    if (response.question)
                        setHistory(history => [...history, response.question]);
                    if (response.results) {
                        setResults(results);
                    }
                };
                setIsCalling(true); // Update button to "Stop" when conversation starts
            }
        }
    };

    if (loading)
        return <Layout style={{height: "100vh", backgroundColor: "#dddde8"}}>
            <Flex justify="center" vertical={false} style={{height: "100%"}}>
                <Flex justify="center" vertical={true} style={{height: "100%"}}>
                    <Card style={{width: 1100, height: 600}}>
                    </Card>
                </Flex></Flex>

        </Layout>


    if (end)
        return <Results result={results}/>

    return (
        <Layout style={{height: "100vh", backgroundColor: "#08979C"}}>
            <Flex justify="center" vertical={false} style={{height: "100%"}}>
                <Flex justify="center" vertical={true} style={{height: "100%"}}>
                    <Card style={{width: 1100, height: 600}}>
                        <img alt="logo" src="/logo.jpg" style={{height: 70, position: "absolute"}}/>
                        <Row>
                            <Col span={12} style={{marginTop: 50}}>
                                {!isCalling ?
                                    <Flex vertical align="flex" justify="space-between"
                                          style={{padding: 42, textAlign: "center"}}>
                                        {permissions ? <h1>Vacature: Marketing Manager</h1> : <h1>Toegang nodig</h1>}
                                        <p>Selecteer de camera en microfoon die je wil gebruiken. Controleer of je
                                            hiernaast jezelf goed ziet en dat de balk onderaan beweegt wanneer je
                                            spreekt.</p>
                                        <Space direction="vertical">
                                            <Select
                                                value={deviceId}
                                                style={{width: 420}}
                                                onChange={setDeviceId}
                                                options={devices.map(d => ({value: d.deviceId, label: d.label}))}
                                            />
                                            <Select
                                                value={audioDeviceId}
                                                style={{width: 420}}
                                                onChange={setAudioDeviceId}
                                                options={audioDevices.map(d => ({value: d.deviceId, label: d.label}))}
                                            />
                                            <Button type="primary"
                                                    onClick={() => toggleConversation()}>Volgende</Button>
                                        </Space>

                                    </Flex> : <Conversation history={history}/>}
                                <Flex vertical align="center" justify="space-between" style={{padding: 42}}>
                                    {recorder.mediaRecorder && (
                                        <div style={{marginTop: -50}}><LiveAudioVisualizer
                                            mediaRecorder={recorder.mediaRecorder}
                                            width={200}
                                            height={50}
                                            barWidth={10}
                                            barColor="#08979C"
                                        /></div>
                                    )}
                                </Flex>
                            </Col>
                            <Col span={12}>
                                <Webcam style={{width: "100%", borderRadius: 10, marginTop: 70}}
                                        videoConstraints={{deviceId: deviceId}}
                                        audioConstraints={{deviceId: audioDeviceId}}/>

                            </Col>
                        </Row>

                    </Card>
                </Flex></Flex>

        </Layout>
    );


}

const Interview = () => {
    const {id} = useParams()
    const [loading, setLoading] = useState(true);
    const [interview, setInterview] = useState(null);


    useEffect(() => {
        const backend = new BackendService();

        async function fetchData() {
            try {
                const interview = await backend.getInterview(id)
                setInterview(interview);

            } catch {
                console.log("No existing interview.")
            }
            setLoading(false);
        }

        fetchData();
    }, [id]);


    if (loading)
        return <></>


    return <>
        {!interview ? <Result
                status="404"
                title="Oh no. Interview does not exist."
                subTitle="Sorry, you seem to be lost. This interview link does not exist anymore."
            /> :
            <DoInterview interview={interview}/>}
    </>

}

export default Interview