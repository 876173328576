import {Col, Row, Card, Space, Typography, Tag, Table, Button} from 'antd';
import {CircularProgressBar} from "@tomik23/react-circular-progress-bar";

const props = {
    percent: 60, // is require
    colorSlice: "#00a1ff",
    colorCircle: "#08979C",
    fontColor: "#365b74",
    fontSize: "1.6rem",
    fontWeight: 400,
    size: 150,
    stroke: 10,
    strokeBottom: 1,
    speed: 500,
    cut: 0,
    rotation: -90,
    opacity: 10,
    unit: "",
    textPosition: "0.35em",
    animationOff: false,
    inverse: false,
    round: true,
    number: true,
    linearGradient: ["#08979C"]
};
const InterviewResults = () => {

    return <div className="container">
        <h1>Interview Report for Ward Van Laer</h1>
        <Row gutter={[30, 30]}>
            <Col span={24}>
                <Card title={<>General Results</>} size="large" style={{height: 300}}
                      bordered={false}>
                    <Row gutter={[30, 30]}>
                        <Col span={20}>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        </Col>
                        <Col span={4}><CircularProgressBar {...props} /></Col>
                    </Row>
                </Card>
            </Col>
            <Col span={12}>
                <Card title={<>General Information</>} style={{height: 200}} size="large"
                      bordered={false}>
                    <p>Remote work: <Tag style={{marginLeft: 10}} color={"#08979C"}>YES</Tag></p>
                    <p>Driving license: <Tag style={{marginLeft: 10}} color={"#08979C"}>YES</Tag></p>

                </Card>
            </Col>
            <Col span={12}>
                <Card title={<>Knockout Questions</>} style={{height: 200}} size="large"
                      bordered={false}>
                    <p>Remote work: <Tag style={{marginLeft: 10}} color={"#08979C"}>OK</Tag></p>
                </Card>
            </Col>
            <Col span={12}>
                <Card title={<>Language: English</>} style={{height: 220}} size="large"
                      bordered={false}>
                    <Row gutter={[0, 0]} style={{marginTop: -20, marginBottom: 10}}>
                        <Col span={6}>
                            Grammar: <Tag style={{marginLeft: 10}} color={"#08979C"}>34</Tag>
                        </Col>
                        <Col span={6}>
                            Vocabulary: <Tag style={{marginLeft: 10}} color={"#08979C"}>34</Tag>
                        </Col>
                        <Col span={6}>
                            Fluency: <Tag style={{marginLeft: 10}} color={"#08979C"}>34</Tag>
                        </Col>
                    </Row>

                    <Row gutter={[30, 30]}>
                        <Col span={16}>
                            De gebruiker lijkt de basis van de taal te kennen, maar heeft moeite met het vormen van
                            complexere zinnen om op de vragen te reageren.
                        </Col>
                        <Col span={4} style={{marginTop: -50}}><CircularProgressBar {...props} /></Col>
                    </Row>
                </Card>
            </Col>

            <Col span={12}>
                <Card title={<>Technical Skill: Python</>} style={{height: 220}} size="large" bordered={false}>
                    <Row gutter={[30, 30]}>
                        <Col span={16}>
                            De gebruiker lijkt de basis van de taal te kennen, maar heeft moeite met het vormen van
                            complexere zinnen om op de vragen te reageren.
                        </Col>
                        <Col span={4} style={{marginTop: -50}}><CircularProgressBar {...props} /></Col>
                    </Row>
                </Card>
            </Col>

            <Col span={24}>
                <Card title={<>Details</>} size="large" style={{height: 300}}
                      bordered={false}>
                    <h2>Interview Transcript</h2>
                </Card>
            </Col>


        </Row>

    </div>


}
export default InterviewResults