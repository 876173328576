import {Layout, Result} from 'antd';
import SideMenu from "./components/Menu";
import Overview from "./components/Overview";
import Interview from "./components/Interview";
import VacancyOverview from "./components/VacancyOverview";
import VacancyResults from "./components/VacancyResults";
import InterviewResults from "./components/InterviewResults";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import VacancyProvider from "./providers/VacancyProvider";
import "./styles/general.css"
import BackendService from "./services/BackendService";
import {useEffect, useState} from "react";
import {useAuth0} from '@auth0/auth0-react';

const {Sider, Content} = Layout;

const AppWithMenu = () => {

    const {isLoading, isAuthenticated, loginWithRedirect} = useAuth0();
    if (isLoading)
        return <></>


    if (!isAuthenticated) {
        loginWithRedirect();
        return
    }


    return <Layout style={{minHeight: "100vh"}} hasSider>
        <VacancyProvider>
            <Layout>
                <Content style={{
                    margin: 30,
                    overflow: 'initial',
                }}>

                    <Routes>
                        <Route path="/vacancy/:id" element={<VacancyOverview/>}/>
                        <Route path="/vacancy/:id/results" element={<VacancyResults/>}/>
                        <Route path="/vacancy/:id/interview/:iid" element={<InterviewResults/>}/>
                        <Route path="/" element={<Overview/>}/>
                    </Routes>

                </Content>
            </Layout>
        </VacancyProvider>
    </Layout>


}

const App = () => {


    const [offline, setOffline] = useState(false)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const backend = new BackendService();

        async function fetchData() {
            try {
                await backend.ping()
                setOffline(false);
            } catch {
                setOffline(true);
            }
            setLoading(false)
        }

        fetchData();
    }, []);


    if (loading)
        return <></>

    if (!loading && offline)
        return (
            <Result
                status="404"
                title="We seem to be offline."
                subTitle="We are working hard to get our servers back up and running."
            />
        )


    return (
        <BrowserRouter>
            <Routes>
                <Route path="/interview/:id/*" element={<Interview/>}/>
                <Route path="/*" element={<AppWithMenu/>}>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}
export default App