import {Col, Row, Card, Space, Typography, Tag, Table, Button} from 'antd';
import {Stat, StatCard} from "./cards/Statistic";
import Vacancy from "./cards/Vacancy";
import {CheckSquareOutlined, ClockCircleOutlined, PieChartOutlined} from "@ant-design/icons";
import {useState} from "react";
const { Paragraph } = Typography;

const VacancyResults = () => {
    const columns = [
        {
            title: 'Naam',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Interview lengte',
            dataIndex: 'duration',
            key: 'duration',
            render: (t) => {return (t +  " min.")}
        },
        {
            title: 'Score',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'Info',
            key: 'tags',
            dataIndex: 'tags',
            render: (_, { tags }) => (
                <>
                    {tags.map((tag) => {
                        return (
                            <Tag color={"grey"} key={tag}>
                                {tag.toUpperCase()}
                            </Tag>
                        );
                    })}
                </>
            ),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <a>Bekijken</a>
                </Space>
            ),
        },
    ];
    const waiting = [
        {
            title: 'Naam',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Email geopend',
            dataIndex: 'opened',
            key: 'opened',
            render: (t) => {return (<Tag color={"orange"}>Ongeopend</Tag>)}
        },
    ];
    const data = [
        {
            key: '1',
            name: 'John Brown',
            duration: 5,
            address: 'New York No. 1 Lake Park',
            tags: ['REMOTE: JA', 'ERVARING: BEPERKT'],
        },
        {
            key: '2',
            name: 'Jim Green',
            duration: 2,
            address: 'London No. 1 Lake Park',
            tags: ['REMOTE: NEE', 'ERVARING: BEPERKT'],
        },
        {
            key: '3',
            name: 'Joe Black',
            duration: 3,
            address: 'Sydney No. 1 Lake Park',
            tags: ['REMOTE: JA', 'ERVARING: GEEN'],
        },
    ];

    return <>
        <Button type="primary" style={{position:"absolute", right: 30}} href="/interview/123"> Test Gesprek</Button>
        <h2>Vacature: Marketing Manager</h2>
        <Row gutter={[30, 30]}>
            <Col span={24}>
                <Card title={<>Interviews</>} size="large"
                      bordered={false}>
                    <Table columns={columns} dataSource={data} />
                </Card>
            </Col>
            <Col span={24}>
                <Card title={<>Wachtende</>} size="large"
                      bordered={false}>
                    <Table columns={waiting} dataSource={data} />
                </Card>
            </Col>

        </Row>


    </>


}
export default VacancyResults