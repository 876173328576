import {createContext, useEffect, useState} from 'react';
import BackendService from "../services/BackendService";

export const VacancyContex = createContext([]);


export default function VacancyProvider({children}) {
    const [vacancies, setVacancies] = useState(null);


    useEffect(() => {

        async function fetchData() {
            const backend = new BackendService();
            try {
                const vac = await backend.getVacancies()
                setVacancies(vac);
            } catch {
                console.log("No existing interview.")
            }
        }

        fetchData();
    }, []);

    async function update(vacancy) {
        const backend = new BackendService();
        console.log(vacancy);
        await backend.updateVacancy(vacancy._id, vacancy)
    }


    return (
        <VacancyContex.Provider
            value={{vacancies, update}}>
            {children}
        </VacancyContex.Provider>
    )
}