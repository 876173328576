import axios from "axios";
import qs from "qs";

const baseURL = process.env.REACT_APP_URL
console.log(baseURL);


const api = axios.create({
    baseURL,
    paramsSerializer: (params) => qs.stringify(params, {indices: false}),
    headers: {
        "Content-Type": "application/json",
    },
});


export default api;