import api from "./api";


export default class BackendService {

    //USER
    async getInterview(id) {
        const resp = await api.get('/interview/' + id)
        return resp.data
    }

    async getVacancies() {
        const resp = await api.get('/vacancy/all')
        return resp.data
    }

    async getVacancy(id) {
        const resp = await api.get('/vacancy/' + id)
        return resp.data
    }

    async updateVacancy(id, content) {
        const resp = await api.post('/vacancy/' + id, content)
        return resp.data
    }

    async ping() {
        const resp = await api.get('/ping')
        return resp.data
    }
}