import { Statistic, Card } from 'antd';

const Stat = ({icon, number, description}) => {


    return <div>
        <div style={{
            backgroundColor: "#b4dfe1",
            color: " #08979C",
            width: 50,
            height: 50,
            textAlign: "center",
            fontSize: 30,
            float: "left"
        }}>{icon}</div>
        <div style={{marginLeft: 70, display: "block"}}>
            <div style={{fontWeight: 800, fontSize: 18}}>{number}</div>
            <div>{description}</div>
        </div>
    </div>
}

const StatCard = ({name, value, color}) => {


    return  <Card bordered={false} size="small">
        <Statistic
            title={name}
            value={value}
            precision={0}
            valueStyle={{
                color: color,
            }}
        />
    </Card>
}
export {Stat, StatCard}