import { Col, Row} from 'antd';
import {StatCard} from "./cards/Statistic";
import Vacancy from "./cards/Vacancy";
import {useContext} from "react";
import {VacancyContex} from "../providers/VacancyProvider";


const Overview = () => {
    const {vacancies} = useContext(VacancyContex)


    return <>
        <Row gutter={[30, 30]}>
        <Col span={6}>
            <StatCard value={2} name={"Open Vacatures"}/>
        </Col>
            <Col span={6}>
                <StatCard value={13} name={"Geplande Interviews"}/>
            </Col>
            <Col span={6}>
                <StatCard value={179} name={"Uitgevoerde Interviews"}/>
            </Col>
            <Col span={6}>
                <StatCard value={200} name={"Interview Credits"}/>
            </Col>
    </Row>
    <h2>Laatste vacatures</h2>
    <Row gutter={[30, 30]}>
        {vacancies?.map((vac) => (
            <Col span={12}>
                <Vacancy data={vac}/>
            </Col>
        ))}
    </Row></>


}
export default Overview